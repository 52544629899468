<template>
  <div>
    <validation-observer
      tag="div" class="mb-05"
      v-for="(room, index) in rowData.rooms"
    >
      <validation-provider
        tag="b-row"
        #default="{ errors }"
        :name="$t(slotConfig.label)"
        :rules="slotConfig.rule"
      >
        <b-col cols="12" class="d-flex">
          <b-form-input style="flex:0 0 100px;" type="text" v-model="room.roomName"/>
          <div
            class="ml-1"
            v-if="isEdit"
            style="flex:0 0 90px;"
          >
            <v-select
              label="text"
              :options="options"
              v-model="room.isActive"
              :reduce="text => text.value"
              :clearable="false"
              :searchable="false"
              :disabled="!room.roomID"
            >
              <template #no-options>
                {{ $t('common.no_options') }}
              </template>
            </v-select>
          </div>
          <b-button
            class="flex-shrink-0 ml-1"
            :disabled="(index === 0)"
            variant="secondary"
            @click="changeIndex('up', index, room)"
          >
            <!--{{ $t('common.up') }}-->
            <feather-icon icon="ArrowUpIcon"/>
          </b-button>
          <b-button
            class="flex-shrink-0 ml-1"
            :disabled="(index === rowData.rooms.length - 1)"
            variant="secondary"
            @click="changeIndex('down', index, room)"
          >
            <!--{{ $t('common.down') }}-->
            <feather-icon icon="ArrowDownIcon"/>
          </b-button>
          <b-button
            class="ml-1"
            v-if="room.tmp_id" variant="outline-danger"
            style="flex:0 0 auto;"
            @click="deleteRoom(room.roomID, room.tmp_id)"
          >
            <!--{{ $t('common.delete') }}-->
            <feather-icon icon="MinusCircleIcon"/>
          </b-button>
        </b-col>
        <b-col cols="12" v-show="errors.length !== 0"><small class="text-danger">{{ errors[0] }}</small></b-col>
      </validation-provider>
    </validation-observer>
    <b-button variant="outline-primary" @click="addRoom">
      <!--{{ $t('floor.add_room') }}-->
      <feather-icon icon="PlusCircleIcon"/>
    </b-button>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import common from '@/common'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  max,
  digits,
  alphaDash,
  length,
} from '@validations'

export default {
  name: 'SlotRoom',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    rowData: { type: Object },
    isEdit: { type: Boolean },
    slotConfig: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      tmp_id: 1,
      options: [
        {
          text: common.getI18n('common.enable'),
          value: true
        },
        {
          text: common.getI18n('common.disable'),
          value: false
        },
      ]
    }
  },
  methods: {
    addRoom: function () {
      this.rowData.rooms.push({
        tmp_id: this.tmp_id++,
        roomName: ''
      })
    },
    deleteRoom: function (roomID, tmp_id) {
      for (const i in this.rowData.rooms) {
        if (roomID && this.rowData.rooms[i].roomID === id) {
          this.rowData.rooms.splice(i, 1)
        }

        if (tmp_id && this.rowData.rooms[i].tmp_id === tmp_id) {
          this.rowData.rooms.splice(i, 1)
        }
      }
    },
    changeIndex: function (type, index, item) {
      let rooms = JSON.parse(JSON.stringify(this.rowData.rooms))
      for (let i in rooms) {
        i = parseInt(i)

        if (i === index) {
          let tmp = {}
          if (type === 'up') {
            tmp = rooms[i - 1]
            rooms[i - 1] = item
          } else {
            tmp = rooms[i + 1]
            rooms[i + 1] = item
          }
          rooms[i] = tmp
          break
        }

      }
      this.rowData.rooms = rooms

      for (const i in this.rowData.rooms) {
        this.rowData.rooms[i]['seq'] = parseInt(i) + 1
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

</style>
