import common from "@/common";

let form_options = {}
form_options.company = []

common.getCompanyOptions().then(res => {
  form_options.company.push.apply(form_options.company, res)
})

export default [
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    rule: 'required',
    options: form_options.company,
    show: common.isAdmin(),
    // edit_disable: true
  },
  {
    name: 'facilityID',
    label: 'common.facility',
    type: 'slot',
    rule: 'required',
    slot: 'facility',
    show: common.isAdmin()
  },
  {
    label: 'common.floor',
    multi_col: true,
    col_list: [
      {
        name: 'floorName',
        label: 'common.floor',
        type: 'text',
        rule: 'required|max: 20',
        // group_col: 3,
        // label_col: 6,
        // style: 'margin-left: 4px'
      },
      {
        name: 'isActive',
        label: 'floor.isActive',
        type: 'select',
        rule: 'required',
        edit_show: true,
        show_label: false,
        // group_col: 2,
        // label_col: 0,
        // style: 'margin-left: -6px',
        options: [
          {text: common.getI18n('floor.active'), value: true},
          {text: common.getI18n('floor.passive'), value: false},
        ]
      }
    ]
  },
  {
    name: 'rooms',
    label: 'common.room',
    type: 'slot',
    slot: 'room',
    slot_config: {
      name: 'room',
      label: 'common.room',
      rule: 'required|max: 20',
    },
    validate_in_slot: true,
    group_col: 9,
    label_col: 2,
  },
  {
    name: 'isSingleFacility',
    label: 'company.isSingleFacility',
    type: 'radio',
    rule: 'required',
    options: [
      {text: common.getI18n('company.single_facility'), value: true},
      {text: common.getI18n('company.multi_facility'), value: false},
    ],
  },

]
