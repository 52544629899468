<template>
  <b-card>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field2
      :is-edit="true"
      :fields="fields2"
      :row-data="row_data"
      post-api="/bedfloor/UpdateFloor"
      finish-router="bedfloor"
      permission="Bed_Floor_Edit"
    >
      <template #facility="scope">
        <slot-facility :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID" :row-data="scope.rowData" :is-edit="scope.isEdit"/>
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData"  :is-edit="scope.isEdit" :slotConfig="scope.slotConfig"/>
      </template>

    </edit-field2>
  </b-card>
</template>

<script>
import fields2 from "./fields2";
import EditField2 from "@/components/EditField2";
import EditFieldHeader from "@/components/EditFieldHeader";
import common from "@/common";
import SlotRoom from "@/views/floor/SlotRoom";
import SlotFacility from "@/views/floor/SlotFacility";

// import SlotStaff from '@/views/group/SlotStaff'
// import SlotBed from '@/views/group/SlotBed'
// import SlotMember from '@/views/group/SlotMember'
// import SlotAlertTime from '@/views/group/SlotAlertTime'

export default {
  name: "Edit2",
  components: {
    SlotFacility,
    SlotRoom,
    EditFieldHeader,
    EditField2,

    // SlotAlertTime,
    // SlotMember,
    // SlotBed,
    // SlotStaff,
  },
  data() {
    return {
      title: common.getMenuName('bedfloor') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields2,
      row_data: {}
    }
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/bedfloor/GetFloor?floorID='+this.id).then(res => {
      this.row_data = res.data.bedFloor
    })
  }
}
</script>

<style scoped>

</style>
